import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  fluid: "",
  class: "items"
}
const _hoisted_2 = { class: "text-caption text-primary" }
const _hoisted_3 = { class: "text-caption golden--text" }
const _hoisted_4 = {
  key: 1,
  fluid: "",
  class: "items"
}
const _hoisted_5 = {
  class: "my-0 py-0 overflow-y-auto",
  style: {"max-height":"65vh"}
}
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "golden--text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_img = _resolveComponent("v-img")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "selection mx-auto bg-grey-lighten-5 mt-3",
      style: {"max-height":"75vh","height":"75vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_linear, {
          active: _ctx.loading,
          indeterminate: _ctx.loading,
          absolute: "",
          location: _ctx.top,
          color: "info"
        }, null, 8 /* PROPS */, ["active", "indeterminate", "location"]),
        _createVNode(_component_v_row, { class: "items px-2 py-1" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pb-0 mb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  density: "compact",
                  clearable: "",
                  autofocus: "",
                  variant: "outlined",
                  color: "primary",
                  label: _ctx.frappe._('Search Items'),
                  hint: "Search by item code, serial number, batch no or barcode",
                  "bg-color": "white",
                  "hide-details": "",
                  modelValue: $options.debounce_search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.debounce_search) = $event)),
                  onKeydown: [
                    _withKeys($options.esc_event, ["esc"]),
                    _withKeys($options.search_onchange, ["enter"])
                  ],
                  ref: "debounce_search"
                }, null, 8 /* PROPS */, ["label", "modelValue", "onKeydown"])
              ]),
              _: 1 /* STABLE */
            }),
            (_ctx.pos_profile.posa_input_qty)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "3",
                  class: "pb-0 mb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      density: "compact",
                      variant: "outlined",
                      color: "primary",
                      label: _ctx.frappe._('QTY'),
                      "bg-color": "white",
                      "hide-details": "",
                      modelValue: _ctx.qty,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.qty) = $event)),
                      modelModifiers: { number: true },
                      type: "number",
                      onKeydown: [
                        _withKeys($options.enter_event, ["enter"]),
                        _withKeys($options.esc_event, ["esc"])
                      ]
                    }, null, 8 /* PROPS */, ["label", "modelValue", "onKeydown"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (_ctx.pos_profile.posa_new_line)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "2",
                  class: "pb-0 mb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.new_line,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.new_line) = $event)),
                      color: "accent",
                      value: "true",
                      label: "NLine",
                      density: "default",
                      "hide-details": ""
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "pt-0 mt-0"
            }, {
              default: _withCtx(() => [
                (_ctx.items_view == 'card')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_row, {
                        density: "default",
                        class: "overflow-y-auto",
                        style: {"max-height":"67vh"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filtered_items, (item, idx) => {
                            return (_openBlock(), _createBlock(_component_v_col, {
                              key: idx,
                              xl: "2",
                              lg: "3",
                              md: "6",
                              sm: "6",
                              cols: "6",
                              "min-height": "50"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  hover: "hover",
                                  onClick: $event => ($options.add_item(item))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_img, {
                                      src: item.image ||
                    '/assets/posawesome/js/posapp/components/pos/placeholder-image.png'
                    ,
                                      class: "text-white align-end",
                                      gradient: "to bottom, rgba(0,0,0,0), rgba(0,0,0,0.4)",
                                      height: "100px"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_text, {
                                          textContent: _toDisplayString(item.item_name),
                                          class: "text-caption px-1 pb-0"
                                        }, null, 8 /* PROPS */, ["textContent"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src"]),
                                    _createVNode(_component_v_card_text, { class: "text--primary pa-1" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.currencySymbol(item.currency) || "") + " " + _toDisplayString(_ctx.formatCurrency(item.rate) || 0), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatFloat(item.actual_qty) || 0) + " " + _toDisplayString(item.stock_uom || ""), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.items_view == 'list')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_v_data_table, {
                          headers: $options.getItemsHeaders(),
                          items: $options.filtered_items,
                          "item-key": "item_code",
                          "item-value": "item-",
                          class: "elevation-1",
                          "items-per-page": _ctx.itemsPerPage,
                          "hide-default-footer": "",
                          "onClick:row": $options.click_item_row
                        }, {
                          "item.rate": _withCtx(({ item }) => [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.currencySymbol(item.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.rate)), 1 /* TEXT */)
                          ]),
                          "item.actual_qty": _withCtx(({ item }) => [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatFloat(item.actual_qty)), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["headers", "items", "items-per-page", "onClick:row"])
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, { class: "cards mb-0 mt-3 pa-2 bg-grey-lighten-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          "no-gutters": "",
          align: "center",
          justify: "center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  items: _ctx.items_group,
                  label: _ctx.frappe._('Items Group'),
                  density: "compact",
                  variant: "outlined",
                  "hide-details": "",
                  modelValue: _ctx.item_group,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = $event => ((_ctx.item_group) = $event)),
                    $options.search_onchange
                  ]
                }, null, 8 /* PROPS */, ["items", "label", "modelValue", "onUpdate:modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "3",
              class: "mt-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn_toggle, {
                  modelValue: _ctx.items_view,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.items_view) = $event)),
                  color: "primary",
                  group: "",
                  density: "compact",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      size: "small",
                      value: "list"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.__("List")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_btn, {
                      size: "small",
                      value: "card"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.__("Card")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "4",
              class: "mt-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  size: "small",
                  block: "",
                  color: "primary",
                  variant: "text",
                  onClick: $options.show_coupons
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.couponsCount) + " " + _toDisplayString(_ctx.__("Coupons")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "5",
              class: "mt-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  size: "small",
                  block: "",
                  color: "primary",
                  variant: "text",
                  onClick: $options.show_offers
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.offersCount) + " " + _toDisplayString(_ctx.__("Offers")) + " : " + _toDisplayString(_ctx.appliedOffersCount) + " " + _toDisplayString(_ctx.__("Applied")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}